import React from 'react'
import { graphql } from 'gatsby'
import ProjectTile from '../components/ProjectTile'
import autoHotCap from '../assets/images/autohot_cap.png'
import resumeCap from '../assets/images/resume_cap.png'
import FlexLink from '../components/FlexLink'
import Page from '../components/Page'

class ProjectsRoute extends React.Component {

  render() {
    return (
      <Page {...this.props}>
        <div className="tiles">

          <ProjectTile name="Middesk" link="/posts/middesk/" tags="work,consulting" readmore>
            <p><FlexLink to="https://www.middesk.com">Middesk</FlexLink> is a Y-Combinator graduate (W19) in the financial space that helps companies perform
              due diligence for B2B loans, acquisitions, and business development.
            </p>
            <p>I upgraded their data pipeline from a prototype to a robust production-ready CI/CD enabled workflow. Our data processing
              engine was <FlexLink to="https://spotify.github.io/scio/">Scio (a Scala wrapper for Apache Beam) </FlexLink>
              running on Google Cloud's Dataflow platform.
            </p>
            <p>
              Shortly after our
              engagement, <FlexLink to="https://techcrunch.com/2019/09/12/middesk/">Middesk raised $4m from Sequoia, Accel, and Y-Combinator </FlexLink>
              on the strength of their data product.
            </p>
          </ProjectTile>

          <ProjectTile name="Assembled Brands" link="https://assembledbrands.com/" tags="work,consulting">
            <p>Assembled Brands is a Financial Technology company providing credit, analytics, and a partnership network to direct-to-consumer brands.</p>
            <p>At AB I worked with <FlexLink to="https://alejandrocremades.com/adam-pritzker/">Adam Pritzker </FlexLink>
               (formerly of General Assembly, sold 2018).
              I led the Product and Engineering teams in migrating their data from a spreadsheet-based system into a proper database enabling cross-portfolio
              monitoring and analytics. I led the technical side of a build vs buy evaluation of a portfolio management system.
              I streamlined their loan application process by implementing OAuth connectors to automatically pull reports from Shopify, Quickbooks, and several
              other sources, reducing sales funnel time. I implemented a
               <FlexLink to="https://blog.cleancoder.com/uncle-bob/2012/08/13/the-clean-architecture.html"> layered code architecture </FlexLink>
               to fix the broken architecture left by previous developers which resulted in increased development speed. I developed a hiring plan to build out
              the Product and Engineering teams.
            </p>
            <p>Referred to by Adam Pritzker as "wicked smart".</p>
          </ProjectTile>

          <ProjectTile name="Vertebrae" link="https://www.vertebrae.com" tags="work,consulting">
            Set strategy for pivoting to an augmented reality advertising product in Javascript, Node.js, and Unity. Managed launch of AR ads for virtual-try-on sunglasses and hats. Managed mobile web in-browser ad launch for "Jigsaw" movie with Lionsgate and Atom tickets. Planned strategy for launching an AR ad SDK.
          </ProjectTile>

          <ProjectTile name="Honey" link="https://www.joinhoney.com" tags="work">
            Defined product roadmap by aligning the needs of CEO, COO, CTO, and department heads. Periodically presented roadmap progress and updates to all employees.
            Managed five product managers and three designers to deliver five new products under tight deadlines
          </ProjectTile>

          <ProjectTile name="Gravity" link="https://github.com/GravityLabs" tags="work">
            <span>At Gravity I built a real-time bidding system (RTB) for delivering ads to online publishers.</span>
          </ProjectTile>

          <ProjectTile name="Auto Hot" link="https://github.com/randyunger/AutoHot" width="3" tags="opensource">
            <span><img src={autoHotCap} alt="Screen cap" width="150px" className="projectImage"/></span>
            <span>
              AutoHot is an open-source demonstration of a lightweight, auto-refresh caching pattern.
              I wrote an article about it here.
            </span>
          </ProjectTile>

          <ProjectTile name="Blokus" tags="fun">
            <span>A Blokus game framework I built to pit gameplay AIs head-to-head</span>
          </ProjectTile>

          <ProjectTile name="ParkWait" tags="fun">
              <span>
                A data tool to collect, analyze, and display ride wait times at various themeparks.
                Useful for getting quick info regarding wait times at Disneyland!
              </span>
          </ProjectTile>

          <ProjectTile name="Resume Site" link="https://resume.randleunger.com" width="1" tags="work">
            <span><img src={resumeCap} alt="Screen cap" width="150px" className="resumeImage"/></span>
            <span>My Resume is written in simple but clean HTML and CSS. I print to PDF in case of upload.</span>
          </ProjectTile>

          <ProjectTile name="HackForLA/Heart" tags="volunteer">
            <span>
              Heart is an open source project of <FlexLink to="https://hackforla.org">HackForLA</FlexLink>.
              It is a CRM system to aid low-income residents of LA county
              in accessing citation forgiveness programs.
            </span>
          </ProjectTile>

          <ProjectTile name="CDMT" link="https://cdmt.io" tags="work">
            <span>CDMT is my development studio focused on business process automation for SMBs. Let's talk about how to automate your business!</span>
          </ProjectTile>

          <ProjectTile name="LuLight">
            <span>
              A home-automation component that interfaces with my Lutron lighting system.
              Includes a custom Alexa skill that allows me to control my lighting and scenes via voice!
            </span>
          </ProjectTile>

          <ProjectTile name="ExtrPlayer">
            <span>
              A home-automation component for controlling my whole-home audio system.
              Uses a REST interface to control an Extron matrix audio switch for per-room sound control via web-app.
            </span>
          </ProjectTile>

          <ProjectTile name="Steno">
            <span>
              A tool for refactoring Scala code. Enables you to extend your method return types with notes about
              what actions were performed within a method, such as detailed in-app logging, timing notes, etc.
            </span>
          </ProjectTile>

          <ProjectTile name="Alexa Count">
            <span>
              An Alexa voice game I made for my son. At 3 he was interested in math and counting.
              He would ask me to count by 25s to 500, or count by 4s to 80, etc. So I made an Alexa skill for him to play with.
            </span>
          </ProjectTile>

          <ProjectTile name="Punditt">
            <span>
              Punditt was an early startup I co-founded with two friends. We ingested tweets from self-proclaimed stock pundits
              and tracked their performance. Like StockTwits with accountability.
            </span>
          </ProjectTile>

          <ProjectTile name="Roxy">
            <span>
              A slackbot. Connects a website to a slack chat room. You can try it out on this site!
            </span>
          </ProjectTile>
        </div>
      </Page>
    )
  }
}

export default ProjectsRoute

export const pageQuery = graphql`
  query ProjectsQuery {
    ...siteQuery
  }
`
