import React from 'react'
import './style.scss'
import FlexLink from '../FlexLink'

class ProjectTile extends React.Component {
  render() {
    const {
      children,
      name,
      link,
      width,
    } = this.props

    const wid = width || 2
    const c = `w${wid}`

    return (
      <div className={`projectTile ${c}`}>
        <div className="projectTile-heading">
          <FlexLink to={link}>
            <h3>{name}</h3>
            <section className="bar">&nbsp;</section>
          </FlexLink>
        </div>
        {children}
        <span className="floatRight">
          <FlexLink to={link}>Read more -></FlexLink>
        </span>
      </div>
    )
  }
}

export default ProjectTile
